<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filtres
      </h5>
      <!-- <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-primary"
        class="btn-icon"
        @click="resetFilters"
      >
        Réinitialiser
      </b-button> -->
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          lg="3"
          class="mb-md-0"
        >
          <label>Type</label>
          <v-select
            :get-option-value="(option) => option.id"
            :get-option-label="(option) => option.name"
            :options="typeOptions"
            class="w-100"
            @input="(val) => changeFilter(val, 'TYPE')"
          />
        </b-col>
        <b-col
          cols="12"
          lg="3"
          class="mb-md-0"
        >
          <label>Lugnium</label>
          <v-select
            :get-option-value="(option) => option.value"
            :get-option-label="(option) => option.name"
            :options="lugniumOptions"
            class="w-100"
            @input="(val) => changeFilter(val, 'LUGNIUM')"
          />
        </b-col>
        <b-col
          cols="12"
          lg="3"
          class="mb-md-0"
        >
          <label>Cofrac</label>
          <v-select
            :get-option-value="(option) => option.value"
            :get-option-label="(option) => option.name"
            :options="cofracOptions"
            class="w-100"
            @input="(val) => changeFilter(val, 'COFRAC')"
          />
        </b-col>
        <b-col
          cols="12"
          lg="3"
          class="mb-md-0"
        >
          <label>Actif</label>
          <v-select
            :get-option-value="(option) => option.value"
            :get-option-label="(option) => option.name"
            :options="activeOptions"
            class="w-100"
            @input="(val) => changeFilter(val, 'ACTIVE')"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    typeOptions: {
      type: Array,
      required: true,
    },
    activeOptions: {
      type: Array,
      required: true,
    },
    lugniumOptions: {
      type: Array,
      required: true,
    },
    cofracOptions: {
      type: Array,
      required: true,
    },
    typeValue: {
      type: Object,
      default: null,
    },
    activeValue: {
      type: Object,
      default: null,
    },
    lugniumValue: {
      type: Object,
      default: null,
    },
    cofracValue: {
      type: Object,
      default: null,
    },
  },
  methods: {
    changeFilter(value, filter) {
      if (filter === 'TYPE') {
        this.$emit('update:typeValue', value)
      }
      if (filter === 'ACTIVE') {
        this.$emit('update:activeValue', value)
      }
      if (filter === 'LUGNIUM') {
        this.$emit('update:lugniumValue', value)
      }
      if (filter === 'COFRAC') {
        this.$emit('update:cofracValue', value)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
