<template>
  <div
    v-if="$store.state.auth.role === 1"
  >
    <device-filters
      :type-options="typeList"
      :lugnium-options="lugniumList"
      :cofrac-options="cofracList"
      :active-options="activeList"
      :type-value.sync="typeValue"
      :lugnium-value.sync="lugniumValue"
      :cofrac-value.sync="cofracValue"
      :active-value.sync="activeValue"
    />
    <b-card no-body>
      <div
        class="mb-0"
      >
        <b-row
          class="m-2 d-flex justify-content-between"
        >
          <b-col
            cols="12"
            md="3"
          >
            <h5>Devices</h5>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-form class="w-100">
                <b-input-group>
                  <b-form-input
                    v-model="searchQuery"
                    placeholder="Recherche..."
                  />
                </b-input-group>
              </b-form>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <div class="mt-1 mt-md-0 d-flex align-items-center justify-content-end">
              <b-link
                :to="{ name: 'devices-create' }"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Ajouter un device
                </b-button>
              </b-link>
            </div>
          </b-col>
        </b-row>
        <b-table
          v-if="devicesList.length > 0"
          id="device-table"
          class="position-relative"
          :items="devicesList.slice(
            (currentPage - 1) * perPage,
            currentPage * perPage,
          )"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="Aucun device trouvé"
        >
          <template #cell(name)="data">
            <b-link
              :to="{ name: 'devices-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block link-hover"
            >
              {{ data.item.name }}
            </b-link>
          </template>

          <template #cell(enterprise)="data">
            <small>
              {{ data.item.enterprise }}
            </small>
          </template>

          <template #cell(user)="data">
            <small>
              {{ data.item.user_id }}
            </small>
          </template>

          <template #cell(deviceType_id)="data">
            <small>
              {{ data.item.deviceType.name }}
            </small>
          </template>

          <template #cell(headAssociate)="data">
            <small>
              {{ data.item.headAssociate }}
            </small>
          </template>

          <template #cell(lugnium)="data">
            <b-avatar
              v-b-tooltip.hover
              :title="tooltipLugnium(data.item)"
              :variant="`light-${resolveCheckLugnium(data.item)}`"
            >
              <feather-icon
                :icon="checkLugnium(data.item)"
              />
            </b-avatar>
          </template>

          <template #cell(cofrac)="data">
            <b-avatar
              :variant="`light-${resolveActiveVariant(data.item.cofrac)}`"
            >
              <feather-icon
                :icon="data.item.cofrac ? 'AwardIcon' : 'XIcon'"
              />
            </b-avatar>
          </template>

          <template #cell(is_active)="data">
            <b-avatar
              :variant="`light-${resolveActiveVariant(data.item.is_active)}`"
            >
              <feather-icon
                :icon="data.item.is_active ? 'CheckIcon' : 'XIcon'"
              />
            </b-avatar>
          </template>
        </b-table>
        <div
          v-else
          class="text-center"
        >
          <b-spinner
            v-if="isLoading"
            class="mt-5 mb-5"
          />
        </div>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ dataMeta.from }} à {{ dataMeta.to }} sur {{ dataMeta.of }} devices</span>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalDevices"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                aria-controls="device-table"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BAvatar,
  BLink, BSpinner, VBTooltip, BFormInput,
  BForm, BInputGroup, BButton, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import DeviceFilters from './DeviceFilters.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BSpinner,
    BFormInput,
    BForm,
    BInputGroup,
    BButton,
    BAvatar,
    DeviceFilters,
    BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      searchQuery: null,
      devicesList: [],
      devicesListTotal: [],
      totalDevices: null,
      tableColumns: [
        { key: 'name', label: 'Nom', sortable: false },
        { key: 'enterprise', label: 'Entreprise', sortable: false },
        { key: 'user', label: 'Utilisateur', sortable: false },
        {
          key: 'deviceType_id', label: 'Type', sortable: false, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'headAssociate', label: 'Tête associée', sortable: false, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'lugnium', label: 'Lugnium', sortable: false, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'cofrac', label: 'Cofrac', sortable: false, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'is_active', label: 'Actif', sortable: false, tdClass: 'text-center', thClass: 'text-center',
        },
      ],
      perPage: 20,
      currentPage: 1,
      // filters
      typeList: [],
      lugniumList: [
        { value: true, name: 'Oui' },
      ],
      cofracList: [
        { value: true, name: 'Oui' },
      ],
      activeList: [
        { value: true, name: 'actif' },
        { value: false, name: 'inactif' },
      ],
      typeValue: null,
      lugniumValue: null,
      cofracValue: null,
      activeValue: null,
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.devicesList.length ? this.devicesList.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      ).length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalDevices,
      }
    },
    allUsers() {
      return store.state.user.usersList
    },
  },
  watch: {
    currentPage(newData) {
      this.currentPage = newData
      return this.devicesList.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      )
    },
    searchQuery(newData) {
      this.currentPage = 1
      this.searchQuery = newData.toLowerCase()
      this.searchDevice()
    },
    typeValue() {
      this.filterDevice()
    },
    lugniumValue() {
      this.filterDevice()
    },
    cofracValue() {
      this.filterDevice()
    },
    activeValue() {
      this.filterDevice()
    },
  },
  created() {
    setTimeout(this.getDevices, 1000)
  },
  methods: {
    resolveActiveVariant(data) {
      if (data) return 'success'
      if (!data) return 'danger'
      return 'dark'
    },
    getDevices() {
      this.isLoading = true
      store.dispatch('device/findAllDevices')
        .then(response => {
          this.devicesList = response.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
          this.devicesListTotal = response.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
          this.totalDevices = response.length
          this.currentPage = 1
          if (this.allUsers) {
            response.map(device => {
              const [users] = this.allUsers.filter(user => user.id === device.user_id)
              if (users && users.username) {
                // eslint-disable-next-line no-param-reassign
                device.user_id = users.username
              }
              return device
            })
          }
          const typeArray = []
          this.$store.state.devicetype.devicetypesList.forEach(elem => {
            typeArray.push({ id: elem.id, name: elem.name })
          })
          this.typeList = [...new Set(typeArray)]
          this.isLoading = false
        })
        .catch(err => {
          console.log('err', err)
          this.isLoading = false
          this.devicesList = []
          this.typeList = []
        })
    },
    searchDevice() {
      this.currentPage = 1
      this.devicesList = this.devicesListTotal.filter(device => {
        if (device.name && device.enterprise) {
          return device.name.toLowerCase().includes(this.searchQuery) || device.enterprise.toLowerCase().includes(this.searchQuery)
        }
        return false
      })
      this.totalDevices = this.devicesList.length
    },
    filterDevice() {
      this.devicesList = this.devicesListTotal
      this.totalDevices = this.devicesList.length
      this.currentPage = 1
      if (this.typeValue) {
        this.devicesList = this.devicesList.filter(device => device.deviceType_id === this.typeValue.id)
        this.totalDevices = this.devicesList.length
      }
      if (this.lugniumValue) {
        this.devicesList = this.devicesList.filter(device => device.events.some(event => event.title === 'Lugnium' && (Date.parse(event.endDate) > Date.now())))
        this.totalDevices = this.devicesList.length
      }
      if (this.cofracValue) {
        this.devicesList = this.devicesList.filter(device => device.cofrac === this.cofracValue.value)
        this.totalDevices = this.devicesList.length
      }
      if (this.activeValue) {
        this.devicesList = this.devicesList.filter(device => device.is_active === this.activeValue.value)
        this.totalDevices = this.devicesList.length
      }
    },
    checkLugnium(data) {
      if (data.events.some(event => event.title === 'Lugnium'
        && (Date.parse(event.endDate) > Date.now())
        && ((Date.parse(event.endDate) - Date.parse(event.startDate)) < 78840000000))) {
        // if lugnium && lugnium < 30 months (78840000000ms)
        return 'ShieldIcon'
      } if (data.events.some(event => event.title === 'Lugnium' && (Date.parse(event.endDate) > Date.now()))) {
        // if lugnium && lugnium >= 30 months
        return 'CheckIcon'
      } return 'XIcon'
    },
    resolveCheckLugnium(data) {
      if (data.events.some(event => event.title === 'Lugnium'
        && (Date.parse(event.endDate) > Date.now())
        && ((Date.parse(event.endDate) - Date.parse(event.startDate)) < 78840000000))) {
        return 'info'
      } if (data.events.some(event => event.title === 'Lugnium' && (Date.parse(event.endDate) > Date.now()))) {
        return 'success'
      } return 'danger'
    },
    tooltipLugnium(data) {
      if (data.events.some(event => event.title === 'Lugnium'
        && (Date.parse(event.endDate) > Date.now())
        && ((Date.parse(event.endDate) - Date.parse(event.startDate)) < 78840000000))) {
        return 'Lugnium < 3ans'
      } return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
